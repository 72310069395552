
import { defineComponent, onBeforeUnmount, Ref, ref } from 'vue'

export interface AudioProps {
  audio: HTMLAudioElement;
  audioStatus: Ref;
  duration: Ref;
}

// 初始化音频
const useInitAutioEffect = (url: string) => {
  // 创建的音频对象
  const audio = new Audio(url)
  const duration = ref('0')
  const audioStatus = ref('play')
  // 当媒介已到达结尾时运行的脚本
  audio.addEventListener('loadedmetadata', () => {
    duration.value = audio.duration.toFixed()
  })

  // 当播放位置改变时
  audio.addEventListener('timeupdate', () => {
    const du = parseInt((audio.duration - audio.currentTime).toFixed())
    if (du < 1) {
      duration.value = '00'
    } else if (du < 10) {
      duration.value = '0' + du
    } else {
      duration.value = du + ''
    }
  })

  // 当媒介已到达结尾时运行的脚本
  audio.addEventListener('ended', () => {
    duration.value = String(audio.duration.toFixed())
    audioStatus.value = 'playComplete'
  })
  return { audio, duration, audioStatus }
}

// 校验音频播放状态
const useCheckAudioEffect = (_audio: AudioProps) => {
  // 获取audio和播放状态
  const { audio, audioStatus } = _audio
  // 判断音频如果是正在播放中，则设置暂停, 如果是暂停，则设置播放
  const status = audioStatus.value
  if (status !== 'play') {
    status === 'playing' ? audio.pause() : audio.play()
    // 更改播放状态
    _audio.audioStatus.value = status === 'playing' ? 'timeOut' : 'playing'
  }
  return _audio
}

// 音频播放
const usePlayAudioEffect = (_audio: AudioProps, initDuration: Ref) => {
  const { audio, audioStatus, duration } = _audio
  if (audioStatus.value === 'playing' || audioStatus.value === 'timeOut') return
  audio.autoplay = true
  audio.play()
  _audio.audioStatus.value = 'playing'
  initDuration.value = duration.value
}

export default defineComponent({
  name: 'MomaiAudioplay',
  props: {
    url: {
      type: String,
      required: true
    },
    showTag: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const audio = useInitAutioEffect(props.url)
    const { duration } = audio
    const initDuration = ref('0')
    const playAudio = () => {
      const checkAudio = useCheckAudioEffect(audio)
      usePlayAudioEffect(checkAudio, initDuration)
    }

    // 组件卸载，清楚audio
    onBeforeUnmount(() => {
      audio.audio.pause()
      delete audio.audio
    })

    return {
      initDuration,
      duration,
      playAudio
    }
  }
})
